import React from 'react';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import './index.scss';

const InfoDashboard: React.FC = () => {
  return (
    <Box className="info-dashboard">
      <Typography className="info-dashboard-text">
        testing info dashboard
      </Typography>
    </Box>
  );
};

export default InfoDashboard;
