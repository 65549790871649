import React, { ReactElement } from 'react';
import Button from '@mui/joy/Button';
import Snackbar from '@mui/joy/Snackbar';
import PlaylistAddCheckCircleRoundedIcon from '@mui/icons-material/PlaylistAddCheckCircleRounded';
import ErrorIcon from '@mui/icons-material/Error';

export interface Props {
  message?: string | null;
  open: boolean;
  // eslint-disable-next-line no-unused-vars
  setOpen: (open: boolean) => void;
  color?: 'success' | 'danger'
}

const ConfirmationSnackbar: React.FC<Props> = ({
  message,
  open,
  setOpen,
  color = 'success',
}): ReactElement => {
  const handleClose = (
    event: React.SyntheticEvent | Event | null,
    reason: 'clickaway' | 'timeout' | 'manual' | 'escapeKeyDown',
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const iconMap = {
    success: <PlaylistAddCheckCircleRoundedIcon />,
    danger: <ErrorIcon />,
    };

  return (
    <React.Fragment>
      <Snackbar
        variant="soft"
        color={color}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        startDecorator={iconMap[color]}
        autoHideDuration={3000}
        endDecorator={
          <Button
            onClick={() => setOpen(false)}
            size="sm"
            variant="soft"
            color={color}
          >
            Dismiss
          </Button>
        }
      >
        {message}
      </Snackbar>
    </React.Fragment>
  );
};

export default ConfirmationSnackbar;
