import React, { ReactElement, useState, useEffect } from 'react';
import Sheet from '@mui/joy/Sheet';
import MessagesPane from './messagesPane';
import MessagePane from './messagePane';
import { NotificationProps } from '../../models/notification';
import { useGetMessagesQuery } from '../../api/MessageApi';
import MessageSkeleton from './Skeleton';
import './index.scss';

const Message: React.FC = (): ReactElement => {
  const {
    data: messages,
    isSuccess,
    refetch,
  } = useGetMessagesQuery({
    property: 'User',
  });

  const [selectedChat, setSelectedChat] = useState<NotificationProps | null>(
    null,
  );

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (isSuccess && messages && messages.length > 0) {
      setSelectedChat(messages[0]);
    }
  }, [isSuccess, messages]);

  if (!messages || !isSuccess || !selectedChat) {
    return <MessageSkeleton />;
  }

  return (
    <div className="message">
      <Sheet
        variant="outlined"
        sx={{
          flex: 1,
          width: '100%',
          mx: 'auto',
          pt: { xs: 'var(--Header-height)', sm: 0 },
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr',
            sm: 'minmax(min-content, min(30%, 400px)) 1fr',
          },
        }}
      >
        <Sheet
          sx={{
            position: { xs: 'fixed', sm: 'sticky' },
            transform: {
              xs: 'translateX(calc(100% * (var(--MessagesPane-slideIn, 0) - 1)))',
              sm: 'none',
            },
            transition: 'transform 0.4s, width 0.4s',
            zIndex: 100,
            width: '100%',
            top: 52,
          }}
        >
          <MessagePane
            messages={messages || []}
            selectedChatId={selectedChat.id}
            setSelectedChat={setSelectedChat}
          />
        </Sheet>
        <MessagesPane chat={selectedChat} />
      </Sheet>
    </div>
  );
};

export default Message;
