// src/slices/messageSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MessageApi } from '../api/MessageApi';
import type { RootState } from '../store';

interface MessageState {
  count: number;
}

const initialState: MessageState = {
  count: 0,
};

const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    setMessageCount: (state, action: PayloadAction<number>) => {
      state.count = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      MessageApi.endpoints.getMessages.matchFulfilled,
      (state, { payload }) => {
        const unreadCount = payload.reduce(
          (acc, userNotification) => acc + userNotification.unCheckedMessages,
          0,
        );
        state.count = unreadCount;
      },
    );
  },
});

export const { setMessageCount } = messageSlice.actions;

export const selectMessageCount = (state: RootState): number =>
  state.message.count;

export const { reducer: messageReducer } = messageSlice;
