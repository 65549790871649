/* eslint-disable no-unused-vars */
import React, { ReactElement } from 'react';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import { EditorState } from 'draft-js';
import Stack from '@mui/joy/Stack';
import Autocomplete from '@mui/joy/Autocomplete';
import { UserRole } from '../../../models/user';
import Typography from '@mui/joy/Typography';
import MessageEditor from '../../MessageEditor';
import { getContentLength } from '../../../Utils/helpers';

type ReplyModalProps = {
  isCurrentUserRequestor: boolean;
  setSendTo: (sendTo: string) => void;
  requestUserName?: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  handleSubmit: () => void;
  mdmUserData?: UserRole;
  setMessage: (messageText: string) => void;
  editorState: EditorState;
  setEditorState: (state: EditorState) => void;
  fileNames: string[];
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const ReplyModal: React.FC<ReplyModalProps> = ({
  isCurrentUserRequestor,
  requestUserName,
  open,
  setOpen,
  handleSubmit,
  setSendTo,
  mdmUserData,
  setMessage,
  editorState,
  setEditorState,
  fileNames,
  handleFileChange,
}): ReactElement => {
  const charLimit = 500;

  const handleTextChange = (text: string) => {
    setMessage(text);
  };

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (getContentLength(editorState) === 0 || getContentLength(editorState) > charLimit) {
      return;
    }
    handleSubmit();
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Modal className="replyModal" open={open} onClose={() => setOpen(false)}>
        <ModalDialog>
          <DialogTitle>Send New Message</DialogTitle>
          <DialogContent>
            Please fill in the following details to send a new message.
          </DialogContent>
          <form
            onSubmit= {handleFormSubmit}
          >
            <Stack spacing={2}>
              <FormControl>
                <FormLabel>Send To</FormLabel>
                {isCurrentUserRequestor ? (
                  <Autocomplete
                    placeholder="Type @ to search email"
                    required
                    options={
                      Array.isArray(mdmUserData)
                        ? mdmUserData.map((user: UserRole) => ({
                            label: `${user.name} (${user.email})`,
                            value: user.id,
                          }))
                        : []
                    }
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Typography className="mdmEmailLabel" color="primary">
                          {option.label}
                        </Typography>
                      </li>
                    )}
                    onInputChange={(e, value) => {
                      if (value.startsWith('@')) {
                        setSendTo(value);
                      } else {
                        setSendTo('');
                      }
                    }}
                    onChange={(e, value) => {
                      const selectedValue = (value as { value: string })?.value;
                      if (selectedValue) {
                        setSendTo(selectedValue);
                      } else {
                        setSendTo('');
                      }
                    }}
                    filterOptions={(options, { inputValue }) => {
                      if (inputValue.startsWith('@')) {
                        return options.filter((option) =>
                          option.label
                            .toLowerCase()
                            .includes(inputValue.substring(1).toLowerCase()),
                        );
                      }
                      return [];
                    }}
                  />
                ) : (
                  <Input
                    disabled={true}
                    autoFocus
                    required
                    value={`@${requestUserName}`}
                  />
                )}
              </FormControl>

              <FormControl>
                <FormLabel>Message</FormLabel>
                <MessageEditor
                  setMessage={handleTextChange}
                  editorState={editorState}
                  setEditorState={setEditorState}
                />
                <Typography>
                  {getContentLength(editorState)}/{charLimit} characters
                </Typography>
                <Typography color="danger">
                  {getContentLength(editorState) === 0 ? 'Please enter a message.' : 
                  getContentLength(editorState) > charLimit ? `Please limit to ${charLimit} characters.` : 
                  null}
                </Typography>
              </FormControl>

              <input
                type="file"
                multiple
                onChange={handleFileChange}
                style={{ marginTop: '10px' }}
              />
              <div className="file-names">
                {fileNames.length > 0 ? (
                  <p>Files attached: {fileNames.join(', ')}</p>
                ) : null}
              </div>

              <Button type="submit">Submit</Button>
            </Stack>
          </form>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
};

export default ReplyModal;
