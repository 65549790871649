const URIS = {
  stores: {
    development:
      process.env.REACT_APP_STORES_URI || 'https://localhost:3000/store',
    // production: 'https://172.30.30.182/store',
    production: 'https://md.seoulgrocery.com/store',
  },
  storefront: {
    development:
      process.env.REACT_APP_STOREFRONT_URI ||
      'http://localhost:8080/storefront',
    // production: 'https://172.30.30.181/storefront',
    production: 'https://hmart.seoulgrocery.com/storefront',
  },
  homepage: {
    development: process.env.REACT_APP_HOMEPAGE_URI || 'https://localhost:3000',
    // production: 'https://172.30.30.182',
    production: 'https://md.seoulgrocery.com',
  },
} as UrisInterface;

type UrisInterface = {
  [key: string]: EnvironmentUris;
};

type EnvironmentKey = 'development' | 'production';

type EnvironmentUris = {
  // eslint-disable-next-line no-unused-vars
  [key in EnvironmentKey]: string;
};

let env: EnvironmentKey;
const appEnv = process.env.REACT_APP_ENV;

if (appEnv === 'development') {
  env = 'development';
} else {
  env = 'production';
}

type Services = {
  [key: string]: string;
};

const serviceKeys = Object.keys(URIS);
const services = serviceKeys.reduce((acc, curr) => {
  return { ...acc, [curr]: URIS[curr][env] };
}, {}) as Services;

export default services;
