import React, { ReactElement, useState } from 'react';
import Avatar from '@mui/joy/Avatar';
import Button from '@mui/joy/Button';
import Chip from '@mui/joy/Chip';
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import CircleIcon from '@mui/icons-material/Circle';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import Modal from '@mui/joy/Modal';
import { UserProps } from '../../models/notification';
import { toggleMessagesPane } from '../../Utils/helpers';
import services from '../../api/remoteServices';
import UserCard from '../UserCard';

type MessagesPaneHeaderProps = {
  sender: UserProps;
};

const MessagesPaneHeader: React.FC<MessagesPaneHeaderProps> = (
  props: MessagesPaneHeaderProps,
): ReactElement => {
  const { sender } = props;
  const [open, setOpen] = useState<boolean>(false);

  const handleViewProfile = () => {
    setOpen(true);
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          borderBottom: '1px solid',
          borderColor: 'divider',
          backgroundColor: 'background.body',
        }}
        py={{ xs: 2, md: 2 }}
        px={{ xs: 1, md: 2 }}
      >
        <Stack direction="row" spacing={{ xs: 1, md: 2 }} alignItems="center">
          <IconButton
            variant="plain"
            color="neutral"
            size="sm"
            sx={{
              display: { xs: 'inline-flex', sm: 'none' },
            }}
            onClick={() => toggleMessagesPane()}
          >
            <ArrowBackIosNewRoundedIcon />
          </IconButton>
          <Avatar size="lg" src={`${services.stores}/${sender.avatar}`} />
          <div>
            <Typography
              fontWeight="lg"
              fontSize="lg"
              component="h2"
              noWrap
              endDecorator={
                sender.online ? (
                  <Chip
                    variant="outlined"
                    size="sm"
                    color="neutral"
                    sx={{
                      borderRadius: 'sm',
                    }}
                    startDecorator={
                      <CircleIcon sx={{ fontSize: 8 }} color="success" />
                    }
                    slotProps={{ root: { component: 'span' } }}
                  >
                    Online
                  </Chip>
                ) : undefined
              }
            >
              {sender.name}
            </Typography>
            <Typography level="body-sm">{sender.name}</Typography>
          </div>
        </Stack>
        <Stack spacing={1} direction="row" alignItems="center">
          <Button
            color="neutral"
            variant="outlined"
            size="sm"
            sx={{
              display: { xs: 'none', md: 'inline-flex' },
            }}
            onClick={handleViewProfile}
          >
            View profile
          </Button>
        </Stack>
      </Stack>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <UserCard {...sender} />
      </Modal>
    </>
  );
};

export default MessagesPaneHeader;
