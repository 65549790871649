import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Code } from '../models/code';
import services from './remoteServices';

export type CodeParamsType = {
  keyword?: string;
  plu?: string;
  deptCode?: string;
  categoryCode?: string;
  pluRange?: string;
};

export type CodeDetailParams = {
  pluCodes: string[];
};

export type UpdateCodeParams = {
  plu: string;
  descripEn?: string;
  descripKo?: string;
};

export const CodeApi = createApi({
  reducerPath: 'CodeApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${services.stores}/api/`,
    credentials: 'include',
  }),
  tagTypes: ['code'],

  endpoints: (builder) => ({
    // remove later
    getCodes: builder.query<Code[], void>({
      query: () => ({
        url: `code/getall`,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      providesTags: ['code'],
    }),
    Search: builder.query<Code[], CodeParamsType>({
      query: (params) => ({
        url: `code/search?deptcode=${params.deptCode}&categoryCode=${params.categoryCode}&keyword=${params.keyword}`,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      providesTags: ['code'],
    }),
    // remove later
    searchCodes: builder.mutation<Code[], CodeParamsType>({
      query: (params) => ({
        url: `code/search`,
        method: 'POST',
        body: params,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    getCodeDetails: builder.mutation<Code[], CodeDetailParams>({
      query: (params) => ({
        url: `code/detail`,
        method: 'POST',
        body: params,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    updateCode: builder.mutation<void, UpdateCodeParams>({
      query: (params) => ({
        url: `code/update`,
        method: 'POST',
        body: params,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
  }),
});

export const {
  useGetCodesQuery,
  useSearchCodesMutation,
  useGetCodeDetailsMutation,
  useSearchQuery,
  useLazySearchQuery,
  useUpdateCodeMutation,
} = CodeApi;
