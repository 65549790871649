import React, { ReactElement } from 'react';
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import AvatarWithStatus from '../avatarWithStatus';
import { ReplyUser } from '../../../models/notificationDetail';
import services from '../../../api/remoteServices';
import { formatDateTime } from '../../../Utils/helpers';

type HeaderProps = {
  userId?: string;
  timeStamp: string;
  replyUser: ReplyUser;
  sentAt: string;
};

const ReplyHeader: React.FC<HeaderProps> = ({
  userId,
  timeStamp,
  replyUser,
  sentAt,
}): ReactElement => {
  const name = replyUser?.userId === userId ? 'You' : replyUser?.name;

  const getUsernameFromEmail = (email: string) => {
    if (!email) return '';

    const atIndex = email.indexOf('@');
    return atIndex !== -1 ? email.slice(0, atIndex) : email;
  };

  return (
    <Stack
      className="replyHeader"
      direction="row"
      sx={{
        justifyContent: 'space-between',
        borderBottom: '1px solid',
        borderColor: 'divider',
        backgroundColor: 'background.body',
        borderRadius: 'sm',
      }}
    >
      <Stack
        direction="row"
        spacing={{ xs: 1, md: 2 }}
        sx={{ alignItems: 'center' }}
      >
        <IconButton
          variant="plain"
          color="neutral"
          size="sm"
          sx={{ display: { xs: 'inline-flex', sm: 'none' } }}
        >
          <ArrowBackIosNewRoundedIcon />
        </IconButton>
        <AvatarWithStatus
          online={true}
          src={`${services?.stores}/${replyUser?.imgUrl}`}
        />
        <div>
          <Typography level="body-sm">
            <span>
              <strong>{`${name}`}</strong>
            </span>
            <span>{`<${replyUser.email}>`}</span>
          </Typography>
          <Typography level="body-sm">
            {sentAt ? `@${getUsernameFromEmail(sentAt)}` : null}
          </Typography>
        </div>
      </Stack>
      <Stack spacing={1} direction="row" sx={{ alignItems: 'center' }}>
        <Typography level="body-sm">{formatDateTime(timeStamp)}</Typography>
        <IconButton size="sm" variant="plain" color="neutral">
          <MoreVertRoundedIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default ReplyHeader;
