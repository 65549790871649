import {
  createSlice,
  createSelector,
  Draft,
  PayloadAction,
} from '@reduxjs/toolkit';
import { userApi } from '../api/UserApi';
import type { RootState } from '../store';
import {
  AdminStoreRoles,
  ManageRoles,
  CombinedRoles,
  InternalRole,
} from '../models/user';
import { useStoreSlug } from '../Hooks/useStoreSlug';

interface StoreRoleDetail {
  roleId: number;
  role: string;
  storeId: number;
  storeName: string;
  storeUrl?: string;
}

export interface UserState {
  token: string | null;
  loginStatus: 'idle' | 'loading' | 'failed';
  email: string | null;
  role: CombinedRoles | null;
  storeRoles?: StoreRoleDetail[] | null;
}

const initialState: UserState = {
  token: null,
  loginStatus: 'idle',
  email: null,
  role: InternalRole.Internal,
  storeRoles: [],
};

const handleLoading = (state: Draft<UserState>) => {
  state.loginStatus = 'loading';
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    clearToken: (state) => {
      state.token = null;
    },
    setEmail: (
      state,
      action: PayloadAction<{
        email: string;
        role: CombinedRoles;
        storeRoles: StoreRoleDetail[];
      }>,
    ) => {
      state.email = action.payload.email;
      state.role = action.payload.role;
      state.storeRoles = action.payload.storeRoles.map((storeRole) => ({
        ...storeRole,
        role: storeRole.role.toLowerCase(),
        storeUrl: storeRole.storeUrl || '',
      }));
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(userApi.endpoints.fetchUser.matchPending, handleLoading)
      .addMatcher(
        userApi.endpoints.fetchUser.matchFulfilled,
        (state, { payload }) => {
          state.loginStatus = 'idle';
          state.email = payload.email;
          state.role = payload.role as CombinedRoles;

          state.storeRoles = payload.storeRoles
            ? payload.storeRoles.map((storeRole) => ({
                ...storeRole,
                role:
                  typeof storeRole.role === 'string'
                    ? storeRole.role.toLowerCase()
                    : storeRole.role,
                storeUrl: storeRole.storeUrl || '',
              }))
            : [];
        },
      );
  },
});

export const selectStoreUrl = createSelector(
  (state: RootState) => state.user,
  (user: UserState): string | undefined => {
    return user.storeRoles?.[0]?.storeUrl;
  },
);

export const selectLoggedIn = createSelector(
  (state: RootState) => state.user,
  (user: UserState): boolean => user.email !== null,
);

export const selectManageRoles = createSelector(
  (state: RootState) => state.user,
  (user: UserState): boolean =>
    Object.values(ManageRoles).includes(user.role as ManageRoles),
);

export const selectUserRole = createSelector(
  (state: RootState) => state.user,
  (user: UserState): CombinedRoles => {
    return user.role ?? InternalRole.Internal;
  },
);

export const { setToken, clearToken, setEmail } = userSlice.actions;

export const { reducer } = userSlice;
