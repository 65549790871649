import { ReactElement, useMemo, useEffect } from 'react';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { useAppDispatch, useAppSelector } from './reduxHooks';
import { MessageApi } from '../api/MessageApi';
import QrCodeIcon from '@mui/icons-material/QrCode';
import StorageIcon from '@mui/icons-material/Storage';
import useIsUserAuthorized from '../Hooks/useIsUserAuthorized';

const URL_TYPE = {
  STORE: 'store',
  NON_STORE: 'nonStore',
} as const;

const USER_TYPE = {
  MDM: 'mdm-sidebar',
  INTERNAL: 'internal-sidebar',
} as const;

type UserTypeKeys = keyof typeof USER_TYPE;
type CurrentUserType = (typeof USER_TYPE)[UserTypeKeys];

type NestedItemType = {
  text: string | ReactElement;
  path: string;
};

type SidebarType = {
  icon: JSX.Element;
  text: string | ReactElement;
  active: boolean;
  nestedItems?: NestedItemType[];
  badge?: number | null;
  path?: string;
};

const SIDEBAR_ITEMS = {
  mdm: [
    {
      icon: <HomeRoundedIcon />,
      text: 'Home',
      active: false,
    },
    {
      icon: <DashboardRoundedIcon />,
      text: 'Dashboard',
      active: false,
      path: '/mdm/manage/dashboard',
    },
    {
      icon: <StorageIcon />,
      text: 'Item Master',
      active: true,
      nestedItems: [
        {
          text: 'Search Plu',
          path: '/mdm/manage/data/plu',
          active: true,
        },
        {
          text: 'Item Master-test',
          path: '/mdm/manage/data/itemmaster',
          active: false,
        },
      ],
    },
    {
      icon: <NotificationsActiveIcon />,
      text: 'Message',
      badge: null,
      active: true,
      path: '/mdm/manage/message',
    },
    {
      icon: <GroupRoundedIcon />,
      text: 'Users',
      active: true,
      nestedItems: [
        {
          text: 'My Profile',
          path: '/mdm/manage/user/myprofile',
          active: true,
        },
      ],
    },
  ],
  internal: [
    {
      icon: <DashboardRoundedIcon />,
      text: 'Dashboard',
      active: false,
      path: `dashboard`,
    },
    {
      icon: <QrCodeIcon />,
      text: 'Item Master',
      active: true,
      nestedItems: [
        {
          text: 'Search Plu',
          path: 'plucode',
          active: true,
        },
        {
          active: true,
          text: 'Code Request',
          path: 'code/request',
        },
      ],
    },
    {
      icon: <NotificationsActiveIcon />,
      text: 'Message',
      badge: null,
      active: true,
      path: 'message',
    },
    {
      icon: <GroupRoundedIcon />,
      text: 'Users',
      active: true,
      nestedItems: [{ text: 'My Profile', path: 'myprofile', active: true }],
    },
  ],
  default: [
    {
      icon: <DashboardRoundedIcon />,
      text: 'Dashboard',
      active: false,
      path: `dashboard`,
    },
  ],
};

const updateBadgeForNotification = (
  items: SidebarType[],
  badgeCount: number | null,
): SidebarType[] => {
  return items.map((item) =>
    item.text === 'Message'
      ? { ...item, badge: badgeCount !== 0 ? badgeCount : null }
      : item,
  );
};

export const useSidebar = (currentUser: CurrentUserType): SidebarType[] => {
  const dispatch = useAppDispatch();
  const isUserAuthorized = useIsUserAuthorized();
  const urlType = isUserAuthorized ? URL_TYPE.NON_STORE : URL_TYPE.STORE;
  const messageCount = useAppSelector((state) => state.message.count);

  useEffect(() => {
    dispatch(MessageApi.endpoints.getMessages.initiate({ property: 'User' }));
  }, [dispatch]);

  const sidebarItems = useMemo(() => {
    switch (urlType) {
      case URL_TYPE.NON_STORE:
        if (currentUser === USER_TYPE.MDM) {
          return updateBadgeForNotification(SIDEBAR_ITEMS.mdm, messageCount);
        }
        return SIDEBAR_ITEMS.mdm;

      case URL_TYPE.STORE:
        if (currentUser === USER_TYPE.INTERNAL) {
          return updateBadgeForNotification(
            SIDEBAR_ITEMS.internal,
            messageCount,
          );
        }
        return SIDEBAR_ITEMS.internal;
      default:
        return SIDEBAR_ITEMS.default;
    }
  }, [urlType, messageCount, currentUser]);

  return sidebarItems;
};

export default useSidebar;
