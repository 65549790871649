/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import Box from '@mui/joy/Box';
import Table from '@mui/joy/Table';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import Checkbox from '@mui/joy/Checkbox';
import IconButton from '@mui/joy/IconButton';
import Tooltip from '@mui/joy/Tooltip';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useLazySearchQuery } from '../../api/CodeApi';
import { useGetDepartmentsQuery } from '../../api/DepartmentApi';
import { Code } from '../../models/code';
import TableToolbar from './TableToolBar';
import { useLazyGetCategoriesQuery } from '../../api/CategoryApi';
import PluDataSkeleton from './Skeleton';
import './index.scss';
import ConfirmationSnackbar from '../ConfirmationSnackbar';

interface CodeData {
  plu: string;
  descripEn: string;
  descripKo: string;
  department: string;
  category: string;
  codeType: string;
}
type Order = 'asc' | 'desc';

function getComparator<Key extends keyof Code>(
  order: Order,
  orderBy: Key,
): (a: Code, b: Code) => number {
  return order === 'asc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator<Key extends keyof Code>(
  a: Code,
  b: Code,
  orderBy: Key,
): number {
  if (orderBy === 'plu') {
    return a.plu < b.plu ? -1 : 1;
  }
  if (orderBy === 'department') {
    return a.department.deptCode < b.department.deptCode ? -1 : 1;
  }
  return a[orderBy] < b[orderBy] ? -1 : 1;
}

const headCells: { id: keyof CodeData; label: string; numeric: boolean }[] = [
  { id: 'plu', label: 'PLU Code', numeric: false },
  { id: 'descripEn', label: 'Description (EN)', numeric: false },
  { id: 'descripKo', label: 'Description (KO)', numeric: false },
  { id: 'department', label: 'Department', numeric: false },
  { id: 'category', label: 'Category', numeric: false },
  { id: 'codeType', label: 'Code Type', numeric: false },
];

interface TableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof CodeData,
  ) => void;
  order: Order;
  orderBy: keyof CodeData;
  rowCount: number;
}

function TableHead(props: TableProps) {
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler =
    (property: keyof CodeData) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <thead>
      <tr>
        <th></th>
        {headCells.map((headCell) => {
          const active = orderBy === headCell.id;
          return (
            <th key={headCell.id}>
              <button
                onClick={createSortHandler(headCell.id)}
                style={{ border: 'none', background: 'none' }}
                className={`header-button ${headCell.id}`}
              >
                {headCell.label}
                {active ? (
                  <ArrowDownwardIcon
                    style={{
                      transform:
                        order === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
                    }}
                  />
                ) : null}
              </button>
            </th>
          );
        })}
      </tr>
    </thead>
  );
}

function labelDisplayedRows({
  from,
  to,
  count,
}: {
  from: number;
  to: number;
  count: number;
}) {
  return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
}

const PluCodeTable: React.FC = () => {
  const { data: departmentData } = useGetDepartmentsQuery();
  const [triggerGetCategories, { data: categoryData }] =
    useLazyGetCategoriesQuery();

  const [triggerSearch, { data: codeDataResult = [], isLoading }] =
    useLazySearchQuery();

  const [dept, setDept] = useState<string>('all');
  const [category, setCategory] = useState<string>('all');
  const [keyword, setKeyword] = useState<string>('');
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof CodeData>('plu');
  const [selected, setSelected] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDeptChange = (event: any, value: string | null) => {
    if (value) {
      setDept(value);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleCategoryChange = (event: any, value: string | null) => {
    if (value) {
      setCategory(value);
    }
  };

  const handleKeywordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value);
  };

  const handleSearch = useCallback(() => {
    const params = {
      deptCode: dept,
      categoryCode: category,
      keyword: keyword || 'all',
    };

    triggerSearch(params);
  }, [dept, category, keyword]);

  useEffect(() => {
    handleSearch();
  }, []);

  useEffect(() => {
    const params = {
      deptCode: dept,
    };

    if (dept) {
      triggerGetCategories(params);
      setCategory('all');
      setPage(0);
    }
  }, [dept]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof CodeData,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event: React.MouseEvent<unknown>, plu: string) => {
    const selectedIndex = selected.indexOf(plu);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      if (selected.length < 10) {
        newSelected = newSelected.concat(selected, plu);
      } else {
        return setOpenSnackbar(true);
      }
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (newPage: number) => {
    const totalPages = Math.ceil(codeDataResult.length / rowsPerPage);

    if (newPage < 0) {
      return;
    }

    if (newPage >= totalPages) {
      setPage(0);
    } else {
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    event: any,
    newValue: number | null,
  ) => {
    setRowsPerPage(parseInt(newValue!.toString(), 10));
    setPage(0);
  };

  const getLabelDisplayedRowsTo = () => {
    if (codeDataResult?.length === -1) {
      return (page + 1) * rowsPerPage;
    }
    return rowsPerPage === -1
      ? codeDataResult.length
      : Math.min(codeDataResult.length, (page + 1) * rowsPerPage);
  };

  const handleClear = () => {
    setSelected([]);
  };

  const handleKeywordClear = () => {
    setKeyword('');
  };

  return (
    <Sheet variant="outlined" className="container">
      <TableToolbar
        numSelected={selected.length}
        dept={dept}
        category={category}
        departmentData={departmentData || []}
        keyword={keyword}
        onDeptChange={handleDeptChange}
        categoryData={categoryData || []}
        onCategoryChange={handleCategoryChange}
        onKeywordChange={handleKeywordChange}
        handleClear={handleClear}
        handleSearch={handleSearch}
        handleKeywordClear={handleKeywordClear}
        selected={selected}
        resultCount={codeDataResult.length}
      />

      {isLoading ? (
        <PluDataSkeleton />
      ) : (
        <>
          <Table
            sx={{
              '--TableCell-headBackground': 'transparent',
              '--TableCell-selectedBackground': (theme) =>
                theme.vars.palette.success.softBg,
              '& thead th:nth-child(1)': {
                width: '40px',
              },
              '& thead th:nth-child(2)': {
                width: '10%',
              },
              '& tr > *:nth-child(n+3)': { textAlign: 'right' },
            }}
          >
            <TableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={codeDataResult?.length || 0}
            />
            <tbody>
              {[...(codeDataResult || [])]
                .sort(getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: Code) => {
                  const isItemSelected = selected.includes(row.plu);
                  return (
                    <tr
                      key={row.plu}
                      onClick={(event) => handleClick(event, row.plu)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      style={
                        isItemSelected
                          ? ({
                              '--TableCell-dataBackground':
                                'var(--TableCell-selectedBackground)',
                              '--TableCell-headBackground':
                                'var(--TableCell-selectedBackground)',
                            } as React.CSSProperties)
                          : {}
                      }
                    >
                      <td>
                        <Checkbox checked={isItemSelected} />
                      </td>
                      <td className="pluCode">{row.plu}</td>
                      <td>{row.descripEn}</td>
                      <td>{row.descripKo}</td>
                      <td>
                        {row.department.nameEN} ({row.department.nameKO})
                      </td>
                      <td>
                        {row.category
                          ? `${row.category?.nameEN} (${row.category?.nameKO})`
                          : null}
                      </td>
                      <td>{row.codeType}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              py: 1,
            }}
          >
            <Typography sx={{ flex: '1 1 100%', marginLeft: '0.5rem' }}>
              Rows per page: {rowsPerPage}
            </Typography>
            <Select
              sx={{ width: '100px' }}
              value={rowsPerPage}
              onChange={handleChangeRowsPerPage}
            >
              <Option value={5}>5</Option>
              <Option value={10}>10</Option>
              <Option value={25}>25</Option>
              <Option value={50}>50</Option>
              <Option value={100}>100</Option>
            </Select>
            <Typography sx={{ textAlign: 'center', minWidth: 200 }}>
              {labelDisplayedRows({
                from: codeDataResult?.length === 0 ? 0 : page * rowsPerPage + 1,
                to: getLabelDisplayedRowsTo(),
                count:
                  codeDataResult?.length === -1 ? -1 : codeDataResult?.length,
              })}
            </Typography>
            <Tooltip title="Previous Page">
              <IconButton onClick={() => handleChangePage(page - 1)}>
                <KeyboardArrowLeftIcon />
              </IconButton>
            </Tooltip>
            <Typography>{page + 1}</Typography>
            <Tooltip title="Next Page">
              <IconButton onClick={() => handleChangePage(page + 1)}>
                <KeyboardArrowRightIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <ConfirmationSnackbar
            message={'You can only select up to 10 items'}
            open={openSnackbar}
            setOpen={setOpenSnackbar}
            color={'danger'}
          />
        </>
      )}
    </Sheet>
  );
};

export default PluCodeTable;
