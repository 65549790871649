/* eslint-disable no-unused-vars */
import React, { ReactElement } from 'react';
import { Routes, Route } from 'react-router-dom';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Header from '../../Components/Header/index';
import Sidebar from '../../Components/Sidebar';
import { BreadcrumbProvider } from '../../Containers/BreadcrumbProvider';
import './index.scss';
import Dashboard from '../../Components/Dashboard';
import MyProfile from '../../Components/User/myProfile';
import PluCodeRequest from '../../Components/ItemMaster/pluCodeRequest';
import Plucode from '../../Components/ItemMaster/index';
import EditPlu from '../../Components/ItemMaster/EditItem';
import Message from '../../Components/Message';
import MessageConfirmation from '../../Components/Message/Confirmation/messageConfirmation';

export interface Props {
  storeSlug: string;
}

const InternalLayout: React.FC<Props> = ({ storeSlug }): ReactElement => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderContents = (slug: string) => {
    return (
      <Routes>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="plucode" element={<Plucode />} />
        <Route path="plucode/detail" element={<EditPlu />} />
        <Route path="code/request" element={<PluCodeRequest />} />
        <Route path="message" element={<Message />} />
        <Route
          path="message/confirmation/:id"
          element={<MessageConfirmation />}
        />
        <Route path="myProfile" element={<MyProfile />} />
      </Routes>
    );
  };

  return (
    <BreadcrumbProvider>
      <CssVarsProvider disableTransitionOnChange>
        <CssBaseline />
        <Box className="internalLayout">
          <Header />
          <Sidebar />
          <Box
            component="main"
            className="internalLayout-main"
            sx={{
              px: { xs: 2, md: 6 },
              pt: {
                xs: 'calc(12px + var(--Header-height))',
                sm: 'calc(12px + var(--Header-height))',
                md: 3,
              },
              pb: { xs: 2, sm: 2, md: 3 },
            }}
          >
            {/* <BreadcrumbTrail storeSlug={storeSlug} /> */}
            {/* <Box
              sx={{
                display: 'flex',
                mb: 1,
                gap: 1,
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: { xs: 'start', sm: 'center' },
                flexWrap: 'wrap',
                justifyContent: 'space-between',
              }}
            >
              <Typography level="h2" component="h1">
                Manage Slug: {storeSlug}
              </Typography>
            </Box> */}
            {renderContents(storeSlug)}
          </Box>
        </Box>
      </CssVarsProvider>
    </BreadcrumbProvider>
  );
};

export default InternalLayout;
