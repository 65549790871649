import React, { ReactElement, useState, useCallback } from 'react';
import Box from '@mui/joy/Box';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import IconButton from '@mui/joy/IconButton';
import Divider from '@mui/joy/Divider';
import { useNavigate } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SettingsIcon from '@mui/icons-material/Settings';
import EditNoteIcon from '@mui/icons-material/EditNote'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ReactComponent as StLogo } from '../../../assets/icons/stLogo.svg';
import './index.scss';

interface SidebarItem {
  path?: string;
  text: string | React.ReactElement;
  icon?: React.ReactElement;
  nestedItems?: SidebarItem[];
  active?: boolean;
}

const Logo = (): React.ReactElement => {
  return <StLogo />;
};

const infoCenterSidebarItems: SidebarItem[] = [
  { path: '/infocenter/dashboard', text: 'Dashboard', icon: <DashboardIcon />, active: true },
  {
    path: '/infocenter/reports',
    text: 'Reports',
    icon: <AssessmentIcon />,
    active: true,
    nestedItems: [
      { path: '/infocenter/reports/sales-order', text: 'Sales Order' },
      { path: '/infocenter/reports/purchase-order', text: 'Purchase Order' },
      { path: '/infocenter/reports/ar', text: 'Accounts Receivable (AR)' },
      { path: '/infocenter/reports/ap', text: 'Accounts Payable (AP)' },
      { path: '/infocenter/reports/inventory-control', text: 'Inventory Control' },
    ],
  },
  {
    path: '/infocenter/requests',
    text: 'Requests',
    icon: <EditNoteIcon />,
    active: true,
    nestedItems: [
      { path: '/infocenter/requests/new', text: 'New Request' },
      { path: '/infocenter/requests/pending', text: 'Pending Requests' },
      { path: '/infocenter/requests/approved', text: 'Approved Requests' },
      { path: '/infocenter/requests/rejected', text: 'Rejected Requests' },
    ],
  },
  { path: '/infocenter/settings', text: 'Settings', icon: <SettingsIcon />, active: true },
];

const InfoCenterSidebar: React.FC = (): ReactElement => {
  const navigate = useNavigate();
  const [openStates, setOpenStates] = useState<{ [key: string]: boolean }>({});

  const navigateTo = useCallback(
    (path?: string) => {
      if (!path) return;
      navigate(path);
    },
    [navigate],
  );

  const toggleItem = (key: string) => {
    setOpenStates((prevStates) => ({
      ...prevStates,
      [key]: !prevStates[key],
    }));
  };

  const renderSidebarItem = (item: SidebarItem, index: number, parentKey: string) => {
    const itemKey = `${parentKey}-${index}`;
    const isOpen = openStates[itemKey] || false;

    return (
      <ListItem key={itemKey} nested={!!item.nestedItems}>
        <ListItemButton onClick={() => navigateTo(item.path)}>
          {item.icon}
          <ListItemContent>
            <Typography level="title-sm">{item.text}</Typography>
          </ListItemContent>
          {item.nestedItems && item.nestedItems.length > 0 && (
            <IconButton onClick={(e) => { e.stopPropagation(); toggleItem(itemKey); }}>
              <KeyboardArrowDownIcon sx={{ transform: isOpen ? 'rotate(180deg)' : 'none' }} />
            </IconButton>
          )}
        </ListItemButton>
        {isOpen && item.nestedItems && item.nestedItems.length > 0 && (
          <List>
            {item.nestedItems.map((nestedItem, nestedIndex) =>
              renderSidebarItem(nestedItem, nestedIndex, itemKey),
            )}
          </List>
        )}
      </ListItem>
    );
  };

  return (
    <Sheet className="infocenter-sidebar">
      <Box className="infocenter-sidebar-header">
        <IconButton onClick={() => navigate('/infocenter')}>
          <Logo />
        </IconButton>
      </Box>
      <Box className="infocenter-sidebar-body">
        <List>
          {infoCenterSidebarItems.map((item, index) =>
            renderSidebarItem(item, index, 'root'),
          )}
        </List>
      </Box>
      <Divider />
    </Sheet>
  );
};

export default InfoCenterSidebar;
