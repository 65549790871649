import React, { ReactElement } from 'react';
import { Routes, Route } from 'react-router-dom';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Header from '../../Components/Header';
import InfoCenterSidebar from '../../Components/Infocenter/Sidebar';
import { BreadcrumbProvider } from '../../Containers/BreadcrumbProvider';
import './index.scss';
import InfoDashboard from '../../Components/Infocenter/InfoDashboard';
// import InfoReports from '../../Components/Infocenter/InfoReports';

const InfoCenterLayout: React.FC = (): ReactElement => {
  return (
    <BreadcrumbProvider>
      <CssVarsProvider disableTransitionOnChange>
        <CssBaseline />
        <Box className="infoCenterLayout">
          <Header />
          <InfoCenterSidebar />
          <Box
            component="main"
            className="infoCenterLayout-main"
            sx={{
              px: { xs: 1, md: 3 },
              pt: {
                xs: 'calc(12px + var(--Header-height))',
                sm: 'calc(12px + var(--Header-height))',
                md: 3,
              },
              pb: { xs: 2, sm: 2, md: 3 },
            }}
          >
            <Routes>
              <Route path="/" element={<InfoDashboard />} />
              {/* <Route path="reports" element={<InfoReports />} /> */}
            </Routes>
          </Box>
        </Box>
      </CssVarsProvider>
    </BreadcrumbProvider>
  );
};

export default InfoCenterLayout;
