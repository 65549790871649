/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/joy/Box';
import Chip from '@mui/joy/Chip';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import Tooltip from '@mui/joy/Tooltip';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ConfirmationSnackbar from '../../ConfirmationSnackbar';
import {
  useGetMessageQuery,
  useGetMessagesQuery,
  useUpdateCheckedStatusMutation,
} from '../../../api/MessageApi';
import AvatarWithStatus from '../avatarWithStatus';
import services from '../../../api/remoteServices';
import Reply from './reply';
import { formatDateTime } from '../../../Utils/helpers';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import ReplyModal from './replyModal';
import { useFetchUserQuery } from '../../../api/UserApi';
import { useSendReplyMutation } from '../../../api/MessageApi';
import { useFetchMDMUserQuery } from '../../../api/UserApi';
import DOMPurify from 'dompurify';
import { EditorState } from 'draft-js';
import FileAttachment from './fileAttachment';

const MessageConfirmation: React.FC = (): React.ReactElement => {
  const { id } = useParams<{ id: string }>();
  const { data: userData } = useFetchUserQuery();
  const userId = userData?.id;
  const reqId = Number(id);
  const [message, setMessage] = useState<string>('');
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const { data, refetch: refetchDetail } = useGetMessageQuery({
    reqId: reqId,
  });
  const { data: mdmUserData } = useFetchMDMUserQuery();

  const { refetch: refetchMessages } = useGetMessagesQuery({
    property: 'User',
  });

  const isCurrentUserRequestor =
    userId && data?.user?.userId ? userId === data.user.userId : false;

  const requestUserName = data?.user.name;
  const isRequestCompleted = data?.isCompleted;
  const isRepliesAvailable = data?.replies?.length
    ? data.replies.length > 0
    : false;
  const [updateStatus] = useUpdateCheckedStatusMutation();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openReplyModal, setOpenReplyModal] = useState<boolean>(false);
  const [sendTo, setSendTo] = useState<string | null>(null);
  const [fileNames, setFileNames] = useState<string[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [sendReply] = useSendReplyMutation();
  const sanitizedMessage = data?.message
    ? DOMPurify.sanitize(data.message)
    : '';

  const handleReplyModalOpen = () => {
    setOpenReplyModal(true);
  };

  const handleReplySubmit = () => {
    if (message.trim()) {
      const formData = new FormData();
      const sendToValue = isCurrentUserRequestor ? sendTo : data?.user.userId;

      formData.append('notificationId', reqId.toString());
      if (userId) {
        formData.append('userId', userId.toString());
      }
      formData.append('messageText', message);

      if (sendToValue) {
        formData.append('sendTo', sendToValue);
      }

      if (files.length > 0) {
        files.forEach((file) => {
          formData.append('files', file);
        });
      }

      sendReply(formData)
        .unwrap()
        .then((response) => {
          setOpenReplyModal(false);
          const typedResponse = response as unknown as { message: string };
          setSnackbarMessage(typedResponse.message);
          setOpenSnackbar(true);
          refetchDetail();
        });
    }
  };

  const handleUpdateStatus = (id?: number) => {
    if (!id) return;

    updateStatus({ reqId: id })
      .unwrap()
      .then((response) => {
        const typedResponse = response as unknown as { message: string };
        setSnackbarMessage(typedResponse.message);
        setOpenSnackbar(true);
        refetchMessages();
        refetchDetail();
      });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(event.target.files || []);
    setFiles(selectedFiles);
    const names = selectedFiles.map((file) => file.name);
    setFileNames(names);
  };

  const renderRequestUserInfo = useMemo(() => {
    return (
      <Box className="header-box">
        <Box className="senderInfo">
          <AvatarWithStatus
            online={true}
            src={`${services.stores}/${data?.user?.imgUrl}`}
          />
          <Box className="senderInfo-detail">
            <Typography
              className="name"
              level="title-sm"
              textColor="text.primary"
            >
              {data?.user.name}
            </Typography>
            <Typography level="body-xs" textColor="text.tertiary">
              {data?.createdAt ? formatDateTime(data.createdAt) : 'N/A'}
            </Typography>
          </Box>
        </Box>
        <Box className="buttonGroup">
          {!isRequestCompleted ? (
            <div>
              {!isCurrentUserRequestor ? (
                <Button
                  size="sm"
                  variant="plain"
                  color="success"
                  startDecorator={<TaskAltIcon />}
                  onClick={() => handleUpdateStatus(data?.id)}
                >
                  Mark as completed
                </Button>
              ) : null}

              <Button
                variant="outlined"
                color="neutral"
                startDecorator={<AlternateEmailIcon />}
                onClick={handleReplyModalOpen}
              >
                Send Message
              </Button>
            </div>
          ) : null}
        </Box>
      </Box>
    );
  }, [data, isCurrentUserRequestor, isRequestCompleted]);

  const renderRequestHeader = useMemo(() => {
    return (
      <Box className="requestType-box">
        <Typography
          level="title-lg"
          textColor="text.primary"
          endDecorator={
            <Chip component="span" size="sm" variant="outlined" color="primary">
              {/* temporarily hardocded */}
              Bellevue
            </Chip>
          }
        >
          {data?.queueType}
        </Typography>
        <Box className="statusDetail">
          <div>
            <Typography className="status" component="span" level="body-sm">
              Status
            </Typography>
            <Tooltip
              size="sm"
              title={
                isRequestCompleted
                  ? 'This request is marked as completed'
                  : 'This request is pending'
              }
              variant="outlined"
            >
              <Chip
                size="sm"
                variant="soft"
                color={isRequestCompleted ? 'success' : 'danger'}
              >
                {isRequestCompleted ? 'completed' : 'pending'}
              </Chip>
            </Tooltip>
          </div>
        </Box>
      </Box>
    );
  }, [data, isRequestCompleted]);

  const renderRequestBody = useMemo(() => {
    return (
      <Box className="body-box">
        <Typography level="title-sm">
          Department:{' '}
          {data?.department
            ? `${data.department.nameKO} (${data.department.nameEN})`
            : null}
        </Typography>
        <Typography level="title-sm">
          Type:{' '}
          {data?.department
            ? `${data.category.nameKO} (${data.category.nameEN})`
            : null}
        </Typography>
        <div>
          {sanitizedMessage ? (
            <div dangerouslySetInnerHTML={{ __html: sanitizedMessage }} />
          ) : (
            <p>No message available.</p>
          )}
        </div>
      </Box>
    );
  }, [data, sanitizedMessage]);

  return (
    <div className="messageConfirmation">
      <Typography
        component="h2"
        sx={{ fontWeight: 'md', fontSize: 'md' }}
        gutterBottom
      >
        <strong>Request Id:</strong> {id}
      </Typography>
      <Sheet
        className="messageConfirmation-container"
        variant="outlined"
        sx={{ borderRadius: 'sm' }}
      >
        {renderRequestUserInfo}
        <Divider className="header-box-divider" />
        {renderRequestHeader}
        <Divider />
        {renderRequestBody}

        {data?.fileAttachments && data.fileAttachments.length > 0 ? (
          <>
            <Divider />
            <FileAttachment attachments={data.fileAttachments} />
          </>
        ) : null}
      </Sheet>

      {isRepliesAvailable ? (
        <Reply replies={data?.replies || []} userId={userId} />
      ) : null}

      <ConfirmationSnackbar
        message={snackbarMessage}
        open={openSnackbar}
        setOpen={setOpenSnackbar}
      />

      <ReplyModal
        isCurrentUserRequestor={isCurrentUserRequestor}
        mdmUserData={mdmUserData}
        requestUserName={requestUserName}
        open={openReplyModal}
        setOpen={setOpenReplyModal}
        handleSubmit={handleReplySubmit}
        setSendTo={setSendTo}
        setMessage={setMessage}
        editorState={editorState}
        setEditorState={setEditorState}
        fileNames={fileNames}
        handleFileChange={handleFileChange}
      />
    </div>
  );
};

export default MessageConfirmation;
