import * as React from 'react';
import Skeleton from '@mui/joy/Skeleton';
import Box from '@mui/joy/Box';

const PluDataSkeleton: React.FC = (): React.ReactElement => {
  const rows = 10;

  return (
    <Box sx={{ padding: '1rem' }}>
      {Array.from(new Array(rows)).map((_, rowIndex) => (
        <Skeleton key={rowIndex} level="h2" variant="text" width="100%" />
      ))}
    </Box>
  );
};

export default PluDataSkeleton;
