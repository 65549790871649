import React, { useMemo } from 'react';
import Box from '@mui/joy/Box';
import ListDivider from '@mui/joy/ListDivider';
import ListItem from '@mui/joy/ListItem';
import ListItemButton, { ListItemButtonProps } from '@mui/joy/ListItemButton';
import Stack from '@mui/joy/Stack';
import { Chip } from '@mui/joy';
import Typography from '@mui/joy/Typography';
import AvatarWithStatus from './avatarWithStatus';
import {
  NotificationProps,
  MessageProps,
  UserProps,
} from '../../models/notification';
import { toggleMessagesPane } from '../../Utils/helpers';
import services from '../../api/remoteServices';

type ChatListItemProps = ListItemButtonProps & {
  id: string;
  unread?: boolean;
  sender: UserProps;
  messages: MessageProps[];
  selectedChatId?: string;
  // eslint-disable-next-line no-unused-vars
  setSelectedChat: (chat: NotificationProps) => void;
};

const NotificationListItem: React.FC<ChatListItemProps> = (
  props: ChatListItemProps,
): React.ReactElement => {
  const { id, sender, messages, selectedChatId, setSelectedChat } = props;
  const selected = selectedChatId === id;

  const countUncheckedMessage = (messages: MessageProps[]) => {
    return messages.filter((item: MessageProps) => item.unread).length;
  };

  const timeAgo = useMemo(() => {
    const messageTime = new Date(messages[0].timestamp);
    const now = new Date();

    const nowUTC = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
    const diffInMinutes = Math.floor(
      (nowUTC.getTime() - messageTime.getTime()) / (1000 * 60),
    );

    if (diffInMinutes < 60) {
      return `${diffInMinutes} minutes ago`;
    } else if (diffInMinutes < 1440) {
      const hours = Math.floor(diffInMinutes / 60);
      return `${hours} hours ago`;
    } else {
      const days = Math.floor(diffInMinutes / 1440);
      return `${days} days ago`;
    }
  }, [messages]);

  return (
    <React.Fragment>
      <ListItem>
        <ListItemButton
          onClick={() => {
            toggleMessagesPane();
            const unreadCount = messages.filter(
              (message) => message.unread,
            ).length;
            setSelectedChat({
              id,
              sender,
              messages,
              unCheckedMessages: unreadCount,
            });
          }}
          selected={selected}
          color="neutral"
          sx={{
            flexDirection: 'column',
            alignItems: 'initial',
            gap: 1,
          }}
        >
          <Stack direction="row" spacing={1.5}>
            <AvatarWithStatus
              online={sender?.online}
              src={`${services?.stores}/${sender?.avatar}`}
            />
            <Box sx={{ flex: 1 }}>
              <Typography level="title-sm">{sender?.name}</Typography>
              <Typography level="body-sm">{sender?.userName}</Typography>
            </Box>
            <Box
              sx={{
                lineHeight: 1.5,
                textAlign: 'right',
              }}
            >
              <Typography
                level="body-xs"
                display={{ xs: 'none', md: 'block' }}
                noWrap
                endDecorator={
                  <Chip
                    variant="soft"
                    color="primary"
                    size="md"
                    slotProps={{ root: { component: 'span' } }}
                  >
                    {countUncheckedMessage(messages)}
                  </Chip>
                }
              >
                {timeAgo}
              </Typography>
            </Box>
          </Stack>
          <Typography
            level="body-sm"
            sx={{
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {messages[0].queueType}
          </Typography>
        </ListItemButton>
      </ListItem>
      <ListDivider sx={{ margin: 0 }} />
    </React.Fragment>
  );
};

export default NotificationListItem;
