/* eslint-disable no-unused-vars */
// user.ts

export type Store = {
  id: number;
  storeName: string;
  urlSlug: string;
  storeRoles?: StoreRoleDetail[];
};

export type User = {
  id: string;
  isAuthenticated: boolean;
  isLockoutEnabled: boolean;
  name: string;
  role: CombinedRoles;
  email: string;
  imageUrl: string;
  storeRoles?: StoreRoleDetail[];
  stores?: Store[];
};

export enum ManageRoles {
  Admin = 'ADMIN',
  Mdm = 'MDM',
}

export enum InternalRole {
  Internal = 'Internal',
}

export enum AdminStoreRoles {
  Admin = 'ADMIN',
}

export enum InternalStoreRole {
  Analytics = 'analytics',
  Label = 'label',
}

export type CombinedRoles = ManageRoles | InternalRole;
export type CombinedStoreRoles = AdminStoreRoles | InternalStoreRole;

export type StoreRoleDetail = {
  storeUrl: string;
  roleId: number;
  role: AdminStoreRoles | InternalStoreRole;
  storeId: number;
  storeName: string;
};

export type UserDetails = {
  email: string;
  role: CombinedRoles;
  storeRoles?: CombinedStoreRoles[];
};

export type UserRole = {
  id: string;
  name: string;
  email: string;
};
