import * as React from 'react';
import Card from '@mui/joy/Card';
import Skeleton from '@mui/joy/Skeleton';
import CardContent from '@mui/joy/CardContent';
import Box from '@mui/joy/Box';

const MessageSkeleton: React.FC = (): React.ReactElement => {
  const leftSkeletons = Array(2).fill(null);
  const rightSkeletons = Array(3).fill(null);

  return (
    <Card
      variant="outlined"
      sx={{
        width: '100%',
        borderRadius: 0,
        '--Card-radius': 0,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
      }}
    >
      <CardContent sx={{ flex: 0.4, gap: 1 }}>
        <Skeleton variant="rectangular" width="100%" height={100} />

        {leftSkeletons.map((_, index) => {
          return (
            <div key={index}>
              <Box
                sx={{
                  m: 'auto',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                }}
              >
                <Skeleton variant="circular" width={48} height={48} />
                <div>
                  <Skeleton
                    variant="rectangular"
                    width={200}
                    height="1em"
                    sx={{ mb: 1 }}
                  />
                  <Skeleton variant="rectangular" width={140} height="1em" />
                </div>
              </Box>
            </div>
          );
        })}
      </CardContent>

      <CardContent sx={{ flex: 0.6, gap: 1 }}>
        <Box
          sx={{
            width: '100%',
            borderRadius: 0,
            '--Card-radius': 0,
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
          }}
        >
          <Skeleton variant="rectangular" width={44} height={44} />
          <div>
            <Skeleton variant="text" width={200} />
            <Skeleton level="body-sm" variant="text" width={300} />
          </div>
        </Box>
        {rightSkeletons.map((_, index) => {
          return (
            <div key={index}>
              <Skeleton variant="rectangular" width="100%" height={60} />
            </div>
          );
        })}
      </CardContent>
    </Card>
  );
};

export default MessageSkeleton;
