import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { NotificationProps } from '../models/notification';
import { NotificationDetail } from '../models/notificationDetail';
import services from './remoteServices';

export type MessageParamsType = {
  property: string;
};

export type MessageDetailParamsType = {
  reqId: number;
};

export type SendMessageParamType = {
  message?: string;
  userId?: string;
  dept?: string;
  category?: string;
  sendTo?: string;
  file?: File;
};

export type UpdateCheckedStatusParamsType = {
  reqId: number;
};

export type SendReplyParamType = {
  notificationId: number;
  userId?: string;
  messageText: string;
  sendTo?: string;
};

export type DownloadFileParamsType = {
  fileId: number;
};

export const MessageApi = createApi({
  reducerPath: 'MessageApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${services.stores}/api/`,
    credentials: 'include',
  }),
  tagTypes: ['message'],
  endpoints: (builder) => ({
    getMessages: builder.query<NotificationProps[], MessageParamsType>({
      query: (params) => ({
        url: `/message/notification?property=${params.property}`,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      providesTags: ['message'],
    }),
    getMessage: builder.query<NotificationDetail, MessageDetailParamsType>({
      query: (params) => ({
        url: `/message/${params.reqId}/detail`,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      providesTags: ['message'],
    }),
    sendMessage: builder.mutation<void, FormData>({
      query: (formData) => ({
        url: `/message/send`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: ['message'],
    }),
    updateCheckedStatus: builder.mutation<void, UpdateCheckedStatusParamsType>({
      query: (params) => ({
        url: `/message/${params.reqId}/updateCheckedStatus`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    sendReply: builder.mutation<void, FormData>({
      query: (formData) => ({
        url: `/message/sendReply`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: ['message'],
    }),
    downloadFile: builder.query<Blob, DownloadFileParamsType>({
      query: (params) => ({
        url: `/message/${params.fileId}/download`,
        method: 'GET',
        responseHandler: (response) => response.blob(),
      }),
      transformResponse: (response: Blob) => {
        return response;
      },
    }),
  }),
});

export const {
  useGetMessagesQuery,
  useGetMessageQuery,
  useSendMessageMutation,
  useUpdateCheckedStatusMutation,
  useSendReplyMutation,
  useDownloadFileQuery,
} = MessageApi;
