import React from 'react';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import CardOverflow from '@mui/joy/CardOverflow';
import Card from '@mui/joy/Card';
import AspectRatio from '@mui/joy/AspectRatio';
import FolderIcon from '@mui/icons-material/Folder';
import { FileAttachment as FileType } from '../../../models/notificationDetail';
import services from '../../../api/remoteServices';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import MicrosoftIcon from '@mui/icons-material/Microsoft';

type FileAttachmentProps = {
  attachments: FileType[];
};

const truncateFileName = (fileName: string, maxLength: number): string => {
  const parts = fileName.split('.');
  const extension = parts.length > 1 ? parts.pop() : '';
  const baseName = parts.join('.');

  if (baseName.length > maxLength) {
    return `${baseName.slice(0, maxLength)}...${
      extension ? '.' + extension : ''
    }`;
  }

  return fileName;
};

const FileAttachment: React.FC<FileAttachmentProps> = ({
  attachments,
}): React.ReactElement => {
  const handleDownload = async (fileId: number, fileName: string) => {
    const response = await fetch(
      `${services.stores}/api/message/${fileId}/download`,
    );
    if (response.ok) {
      const data = await response.blob();
      const url = window.URL.createObjectURL(data); // Blob URL 생성

      const fileExtension =
        fileName.split('.').pop()?.toLowerCase() || 'unknown';
      const downloadFileName = `${fileName}.${fileExtension}`;

      const link = document.createElement('a'); // 앵커 요소 생성
      link.href = url;
      link.setAttribute('download', downloadFileName); // 다운로드 파일명 설정
      document.body.appendChild(link);
      link.click(); // 다운로드 트리거
      link.parentNode?.removeChild(link); // 다운로드 후 링크 제거
      URL.revokeObjectURL(url); // URL 해제
    } else {
      console.error('Download failed:', response.statusText);
    }
  };

  const FileCardList = (items: FileType[]) => {
    return (
      <>
        {items.map((item) => (
          <React.Fragment key={item.filePath}>{fileCard(item)}</React.Fragment>
        ))}
      </>
    );
  };

  const fileCard = (item: FileType) => {
    if (!item || !item.fileName || !item.filePath) {
      return null;
    }

    const fileExtension = item.fileName.split('.').pop()?.toLowerCase();

    switch (fileExtension) {
      case 'jpg':
      case 'jpeg':
      case 'png':
        return (
          <Card variant="outlined">
            <AspectRatio ratio="1">
              <img
                className="attachmentImage"
                onClick={() => handleDownload(item.fileId, item.fileName)}
                src={`${services.stores}/${item.filePath}`}
                alt={item.fileName}
              />
            </AspectRatio>
          </Card>
        );
      case 'pdf':
        return (
          <Card className="pdfCard" variant="outlined" orientation="horizontal">
            <CardOverflow>
              <AspectRatio ratio="1">
                <div>
                  <PictureAsPdfIcon />
                </div>
              </AspectRatio>
            </CardOverflow>
            <Box className="fileName" sx={{ py: { xs: 1, sm: 2 }, pr: 2 }}>
              <Typography
                className="attachmentTitle"
                level="title-sm"
                color="primary"
                onClick={() => handleDownload(item.fileId, item.fileName)}
              >
                {truncateFileName(item.fileName, 15)}{' '}
              </Typography>
            </Box>
          </Card>
        );

      case 'xlsx':
      case 'xls':
      case 'docx':
        return (
          <Card variant="outlined" orientation="horizontal">
            <CardOverflow>
              <AspectRatio ratio="1">
                <div>
                  <MicrosoftIcon />
                </div>
              </AspectRatio>
            </CardOverflow>
            <Box sx={{ py: { xs: 1, sm: 2 }, pr: 2 }}>
              <Typography
                className="attachmentTitle"
                level="title-sm"
                color="primary"
                onClick={() => handleDownload(item.fileId, item.fileName)}
              >
                {truncateFileName(item.fileName, 15)}{' '}
              </Typography>
            </Box>
          </Card>
        );

      case 'zip':
        return (
          <Card variant="outlined" orientation="horizontal">
            <CardOverflow>
              <AspectRatio ratio="1">
                <div>
                  <FolderIcon />
                </div>
              </AspectRatio>
            </CardOverflow>
            <Box sx={{ py: { xs: 1, sm: 2 }, pr: 2 }}>
              <Typography
                className="attachmentTitle"
                level="title-sm"
                color="primary"
                onClick={() => handleDownload(item.fileId, item.fileName)}
              >
                {truncateFileName(item.fileName, 15)}{' '}
              </Typography>
              <Typography level="body-xs">100 MB</Typography>
            </Box>
          </Card>
        );
      default:
        return null;
    }
  };

  return (
    <Box className="attachments">
      <Typography level="title-sm" sx={{ mt: 2, mb: 2 }}>
        Attachments
      </Typography>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexWrap: 'wrap',
          gap: 2,
          '& > div': {
            boxShadow: 'none',
            '--Card-padding': '0px',
            '--Card-radius': theme.vars.radius.sm,
          },
        })}
      >
        {FileCardList(attachments ?? [])}
      </Box>
    </Box>
  );
};

export default FileAttachment;
