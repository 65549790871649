import { ReactElement } from 'react';
import { useStoreSlug } from '../../Hooks/useStoreSlug';

const Dashboard: React.FC = (): ReactElement => {
  // store slug 여부에 따라 dashboard 의 content 를 업데이트
  const storeSlug = useStoreSlug();
  return (
    <div>
      <h2>Dashboard</h2>
      store slug: {storeSlug}
      <ul>
        <li>Plu usage by deparment</li>
        <li>Plu usage by category</li>
        <li>Requst all</li>
        <li>Request all completed</li>
        <li>Request all pending</li>
      </ul>
    </div>
  );
};

export default Dashboard;
