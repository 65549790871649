import React, { ReactElement, useMemo, useState, useEffect } from 'react';
import { Button, FormControl, FormLabel, Checkbox, Box } from '@mui/joy';
import Sheet from '@mui/joy/Sheet';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import {
  useSearchItemMasterDetailQuery,
  useCreateFileMutation,
} from '../../api/BranchItemMasterApi';
import Grid from '@mui/joy/Grid';
import Input from '@mui/joy/Input';
import SearchIcon from '@mui/icons-material/Search';

const DEPARTMENTS = [
  { text: 'Vegetable', value: '1' },
  { text: 'Fruit', value: '2' },
  { text: 'Meat', value: '3' },
  { text: 'Fish', value: '4' },
] as const;

const INDICAT0_OPTIONS = [
  { label: 'Quantity Not Allowed', value: 'quantity_not_allowed' },
  { label: 'Weight or Price Required', value: 'is_weight' },
  { label: 'Quantity Required', value: 'quantity_required' },
  { label: 'Price Required', value: 'price_required' },
  { label: 'Exception Logged', value: 'exception_logged' },
  { label: 'Not For Sale', value: 'not_for_sale' },
  { label: 'Restricted Sale', value: 'restricted_sale' },
  { label: 'No Movement', value: 'no_movement' },
] as const;

const INDICAT1_OPTIONS = [
  { label: 'Taxable A', value: 'taxable_A' },
  { label: 'Taxable B', value: 'taxable_B' },
  { label: 'Taxable C', value: 'taxable_C' },
  { label: 'Taxable D', value: 'taxable_D' },
  { label: 'Food Stampable', value: 'food_stampable' },
  { label: 'Trading Stamps', value: 'trading_stamps' },
  { label: 'No Discount', value: 'no_discount' },
  { label: 'No Coupon Multiplication', value: 'no_coupon_multiplication' },
] as const;

const ItemMasterSettings: React.FC = (): ReactElement => {
  const [itemCode, setItemCode] = useState<string>('');
  const [createFile] = useCreateFileMutation();
  const [searchParams, setSearchParams] = useState<string>('');
  const { data } = useSearchItemMasterDetailQuery(
    { itemCode: searchParams },
    { skip: !searchParams },
  );

  const [department, setDepartment] = useState<string>('');
  const [selectedIndicat0Checkbox, setSelectedIndicat0Checkbox] = useState<
    string[]
  >([]);
  const [selectedIndicat1Checkbox, setSelectedIndicat1Checkbox] = useState<
    string[]
  >([]);

  useEffect(() => {
    if (data) {
      setDepartment(String(data.department_id));
      const indicat0Flags = INDICAT0_OPTIONS.map((option) => option.value);
      const selected0Flags = indicat0Flags.filter((flag) => data[flag]);
      setSelectedIndicat0Checkbox(selected0Flags);

      const indicat1Flags = INDICAT1_OPTIONS.map((option) => option.value);
      const selected1Flags = indicat1Flags.filter((flag) => data[flag]);
      setSelectedIndicat1Checkbox(selected1Flags);
    }
  }, [data]);

  const handleSearch = () => {
    setSearchParams(itemCode);
  };

  const handleDeptChange = (
    event: React.SyntheticEvent | null,
    newValue: string | null,
  ) => {
    setDepartment(newValue || '');
  };

  const handleIndicat0CheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value;
    setSelectedIndicat0Checkbox((prev) =>
      prev.includes(value)
        ? prev.filter((item) => item !== value)
        : [...prev, value],
    );
  };

  const handleIndicat1CheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value;
    setSelectedIndicat1Checkbox((prev) =>
      prev.includes(value)
        ? prev.filter((item) => item !== value)
        : [...prev, value],
    );
  };

  const handleSubmit = () => {
    createFile(); // temp
  };

  const renderDepartment = useMemo(() => {
    return (
      <FormControl>
        <FormLabel>DEPARTMENT</FormLabel>
        <Select
          className="department"
          value={department}
          onChange={handleDeptChange}
        >
          {DEPARTMENTS.map((item) => (
            <Option key={item.value} value={item.value}>
              {item.text}
            </Option>
          ))}
        </Select>
      </FormControl>
    );
  }, [department]);

  const renderIndicat0 = useMemo(() => {
    return (
      <Box className="indicat0">
        <FormLabel>INDICAT0</FormLabel>
        <div className="indicat0-group1">
          {INDICAT0_OPTIONS.slice(0, 4).map(({ label, value }) => (
            <Checkbox
              label={label}
              key={value}
              value={value}
              checked={selectedIndicat0Checkbox.includes(value)}
              onChange={handleIndicat0CheckboxChange}
            />
          ))}
        </div>
        <div className="indicat0-group2">
          {INDICAT0_OPTIONS.slice(4).map(({ label, value }) => (
            <Checkbox
              label={label}
              key={value}
              value={value}
              checked={selectedIndicat0Checkbox.includes(value)}
              onChange={handleIndicat0CheckboxChange}
            />
          ))}
        </div>
      </Box>
    );
  }, [selectedIndicat0Checkbox]);

  const renderIndicat1 = useMemo(() => {
    return (
      <Box className="indicat1">
        <FormLabel>INDICAT1</FormLabel>
        <div className="indicat1-group1">
          {INDICAT1_OPTIONS.map(({ label, value }) => (
            <Checkbox
              label={label}
              key={value}
              value={value}
              checked={selectedIndicat1Checkbox.includes(value)}
              onChange={handleIndicat1CheckboxChange}
            />
          ))}
        </div>
      </Box>
    );
  }, [selectedIndicat1Checkbox]);

  return (
    <div className="itemMasterSettings">
      <Sheet>
        <Grid container spacing={2} sx={{ flexGrow: 1 }}>
          <Grid className="itemMasterSettings-header" lg={12} md={12} sm={12}>
            <Input
              className="searchInput"
              startDecorator={<SearchIcon />}
              value={itemCode}
              onChange={(e) => setItemCode(e.target.value)}
              endDecorator={<Button onClick={handleSearch}>Search</Button>}
            />
            <Button onClick={handleSubmit}>Edit Item</Button>
          </Grid>
          <Grid className="section1" lg={12} md={12} sm={12}>
            {renderDepartment}
            {renderIndicat0}
          </Grid>
          <Grid className="section2" lg={12} md={12} sm={12}>
            {renderIndicat1}
          </Grid>
        </Grid>
      </Sheet>
    </div>
  );
};

export default ItemMasterSettings;
