import React, { ReactElement } from 'react';
import { Routes, Route } from 'react-router-dom';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Header from '../../Components/Header';
import Sidebar from '../../Components/Sidebar';
import { BreadcrumbProvider } from '../../Containers/BreadcrumbProvider';
// import BreadcrumbTrail from '../../components/BreadcrumbTrail';
import Order from '../../Components/Order';
import Message from '../../Components/Message';
import MyProfile from '../../Components/User/myProfile';
import MessageConfirmation from '../../Components/Message/Confirmation/messageConfirmation';
import Plucode from '../../Components/ItemMaster/index';
import EditItem from '../../Components/ItemMaster/EditItem';
import './index.scss';
import ItemMasterSettings from '../../Components/ItemMaster/ItemMasterSettings';

const MdmLayout: React.FC = (): ReactElement => {
  return (
    <BreadcrumbProvider>
      <CssVarsProvider disableTransitionOnChange>
        <CssBaseline />
        <Box className="mdmLayout">
          <Header />
          <Sidebar />
          <Box
            component="main"
            className="mdmLayout-main"
            sx={{
              px: { xs: 1, md: 3 },
              pt: {
                xs: 'calc(12px + var(--Header-height))',
                sm: 'calc(12px + var(--Header-height))',
                md: 3,
              },
              pb: { xs: 2, sm: 2, md: 3 },
            }}
          >
            {/* <BreadcrumbTrail /> */}
            <Routes>
              <Route path="data/plu" element={<Plucode />} />
              <Route path="data/plu/edit" element={<EditItem />} />
              <Route path="data/itemmaster" element={<ItemMasterSettings />} />
              <Route path="order" element={<Order />} />
              <Route path="message" element={<Message />} />
              <Route
                path="message/confirmation/:id"
                element={<MessageConfirmation />}
              />
              <Route path="user/myProfile" element={<MyProfile />} />
            </Routes>
          </Box>
        </Box>
      </CssVarsProvider>
    </BreadcrumbProvider>
  );
};

export default MdmLayout;
